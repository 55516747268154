import { useParams } from '@solidjs/router'
import ProofreadingView from '~/components/proofreading-view/proofreading-view'
import SubscriptionGate from '~/components/subscription-gate/subscription-gate'
import { ProofreadingParams } from '~/types/routes/series/proofreading'

export default function Proofreading() {
  const { id } = useParams<ProofreadingParams>()
  return (
    <SubscriptionGate requiredRole='proofreading'>
      <ProofreadingView chapterId={id} />
    </SubscriptionGate>
  )
}
